import React from "react";
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Main from "./routes/main";
import AddChain from "./routes/AddChain";
// import Fake from "./routes/fake";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="addchain" element={<AddChain />} />
        <Route path="view/:chainId/*" element={<Main />} />
        <Route path="/" element={<Main />} />
        { /*  <Route path="/" element={<Fake />} />*/  }
      </Routes>
    </BrowserRouter>
  );
}

export default App;
