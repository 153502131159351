import React from "react";
import { Drawer,Tabs,TabPanel} from "tea-component";
import style from "./index.module.scss"
import CrossChain from "@src/routes/main/Operation/CrossChain";

export default function Operation({show,close}:{show:boolean,close:()=>void}) {
  const tabs = [
    { id: "invoke", label: "跨链调用" },
  ];
  return (
    <>
        <Drawer
          outerClickClosable={false}
          showMask={false}
          visible={show}
          title="跨链"
          onClose={close}
          className={style.operation_box}
          size={"l"}
        >
          <Tabs tabs={tabs}>
            <TabPanel id="invoke">
              <CrossChain/>
            </TabPanel>
          </Tabs>
        </Drawer>
    </>
  );
}
