/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import { Search } from '@src/utils/apis';
import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router';

export default function SearchLink({ children }: { children: string }) {
  const { chainId } = useParams();
  const navigate = useNavigate();
  const triggerSearch = useCallback(() => {
    Search({
      Id: children,
      ChainId: chainId,
    }).then((res) => {
      if (res.Data?.Type !== undefined) {
        switch (res.Data.Type) {
          case 0:
            navigate(`/view/${res.Data.ChainId}/block/${res.Data.Data}`);
            break;
          case 1:
            navigate(`/view/${res.Data.ChainId}/transaction/${res.Data.Data}`);
            break;
          case 2:
            navigate(`/view/${res.Data.ChainId}/contract/${res.Data.Data}`);
            break;
          // case 3:
          //   navigate(`/${res.Data.ChainId}/contract/${res.Data.Data}`);
          //   break;
          default:
            break;
        }
      }
    });
  }, [children]);
  return <a onClick={triggerSearch}>{children}</a>;
}
