/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */
import React, { useCallback, useEffect, useState } from 'react';
import style from './index.module.scss';
import { BasicArea, DataSource } from 'tea-chart';
import { GetTransactionNumByTime } from '@src/utils/apis';
import { useParams } from 'react-router-dom';
import moment from 'moment';
let timeManage: any;
export default function Chart() {
  const { chainId } = useParams();
  const [transactions, setTransactions] = useState<DataSource>([]);
  const triggerupdate = useCallback(() => {
    timeManage = setTimeout(() => {
      GetTransactionNumByTime({
        ChainId: chainId,
      }).then((res) => {
        if (res.GroupList) {
          const list: DataSource = res.GroupList.reverse().map((item) => ({
            time: moment.unix(item.Timestamp).format('HH:mm'),
            value: item.TxNum,
          }));
          setTransactions(list);
          triggerupdate();
        }
      });
    }, 1800000);
  }, [chainId, transactions]);
  useEffect(() => {
    GetTransactionNumByTime({
      ChainId: chainId,
    }).then((res) => {
      if (res.GroupList) {
        const list: DataSource = res.GroupList.reverse().map((item) => ({
          time: moment.unix(item.Timestamp).format('HH:mm'),
          value: item.TxNum,
        }));
        setTransactions(list);
        triggerupdate();
      }
    });
    return () => {
      clearTimeout(timeManage);
    };
  }, [chainId]);
  return (
    <div className={style.chart}>
      <div className={style.chart_title}>
        最近24H交易
        {/* <Between title="交易统计" content={<div className={style.chart_time}>近24小时</div>} /> */}
      </div>
      <div className={style.chart_c}>
        <BasicArea
          smooth
          height={440}
          position="time*value"
          dataSource={transactions}
          tooltip={{
            enable: true,
            formatter: ([{ title, value }]) => `时间:${title} 交易数量${value}`,
          }}
          theme={{
            classPrefix:"chart",
            color:["#00fb00"],
            backgroundColor:"rgba(20,20,46,.4)"
          }}
        />
      </div>
    </div>
  );
}
