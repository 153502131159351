import React, { useEffect, useState } from "react";
import * as echart from "echarts";
import { GetGeoJson } from "@src/utils/apis";
import { notification } from "tea-component";
import { GeoJSONSourceInput } from "echarts/types/src/coord/geo/geoTypes";
import style from "./index.module.scss";
import { EChartsType } from "echarts";

export default function Map() {
  const [map, setMap] = useState((null! as EChartsType));
  const [geoJson, setGeoJson] = useState((null! as GeoJSONSourceInput));
  useEffect(() => {
    setMap(echart.init(document.getElementById("map")!));
    (async () => {
      try {
        const res = await GetGeoJson();
        setGeoJson(res.data);
        echart.registerMap("云南", res.data);
      } catch (e) {
        console.log(e);
        notification.error({ description: `拉取地图数据失败:${e}` });
      }
    })();
  }, []);
  useEffect(() => {
/*    const dataGeo = [
      {
        "name": "呈贡",
        "value": [
          102.801382,
          24.889275
        ],
        "id": "111111"
      },
      {
        "name": "五华",
        "value": [
          102.704412,
          25.042165
        ],
        "id": "22222"
      }
    ];*/

    const dataGeo = [
      {
        "name": "呈贡",
        "value": [
          102.801382,
          24.889275
        ],
        "id": "111111"
      }
    ];
    const option = {
      tooltip: {
        show: true
      },
      series: [
        {
          type: "effectScatter",
          coordinateSystem: "geo",  // 使用地理坐标系
          // 要有对应的经纬度才显示，先经度再维度
          data: dataGeo,
          showEffectOn: "render",  // 绘制完成后显示特效
          rippleEffect: {
            scale: 10, // 波纹的最大缩放比例
            brushType: "stroke",
            number:8,
          },
          hoverAnimation: true,
          label: {  // 图形上的文本标签
            show: false,
          },
          // 默认样式
          itemStyle: {
            color: "#f20101",
            shadowBlur: 10,
            shadowColor: "#333"
          },
          // 鼠标移入时样式
          emphasis: {
            itemStyle: {
              color: "#f4e925" // 高亮颜色
            }
          },
          zlevel: 1
        }
      ],
      geo: {
        map: "云南",
        roam: true,
        zoom: 1.2,
        itemStyle: {
          areaColor: "#268426",
          borderColor: "#050511"

        },
        label: {
          show: true
        }
      }
    };
    map?.setOption(option);
  }, [geoJson]);

  return (
    <>
      <div className={style.map}>
        <p>节点地图</p>
        <div id={"map"}>

        </div>
      </div>
    </>
  );
}
