/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */
import axios from "axios";
import { Fetch, ResponseInfo } from "./type";
import mock from "../mock";
import { notification } from "tea-component";
import { transformDesc, transformTitle } from "./notification";

// 是否开启mock
const ISMOCK = false;
// mock 情况下接口为一个取配置数据的promise函数
const MOCKFUNC = (url: string) => (params: any, transformResponse?: (resp: ResponseInfo<any>) => void) =>
  new Promise((resolve) => {
    setTimeout(() => {
      transformResponse?.({
        data: mock[url],
        status: 0,
        statusText: "",
        headers: {},
        config: {}
      });
      resolve(mock[url].Response);
    });
  });
const BASEURL = 'http://127.0.0.1:9997/ynuchain';
// const BASEURL = 'http://47.109.61.31:9997/ynuchain';
const instance = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? '/ynuchain' : BASEURL,
});

instance.interceptors.response.use(
  (response: ResponseInfo<any>) => {
    const resp: any = response?.data?.Response;
    if (resp?.Error?.Code) {
      notification.error({
        title: transformTitle(response?.config?.params?.cmb),
        description: transformDesc(resp),
        unique: true,
        duration: 3000
      });
    }
    if (resp) {
      return resp;
    }
  },
  (error) => Promise.reject(error)
);
// 对错误信息进行默认处理的get请求 封装函数
export const createGetChannel = (url: string): Fetch<any, any> => {
  if (ISMOCK) {
    return MOCKFUNC(url);
  }
  return (params: any, transformResponse?: (resp: ResponseInfo<any>) => void) =>
    instance.get("", {
      params: { ...params, yc: url },
      transformResponse
    });
};

// 对错误信息进行默认处理的post请求 封装函数
export const createPostChannel = (url: string, headers?: { [key: string]: string }): Fetch<any, any> => {
  if (ISMOCK) {
    return MOCKFUNC(url);
  }

  return (data: any) => {
    const postData = data instanceof FormData ? data : { ...data };

    return instance.post(
      "",
      postData,
      {
        params: { yc: url },
        headers: headers ? { ...headers } : {}
      }
    );
  };

};

// 单纯处理的post请求 的封装函数
export const createPostFetch = (url: string): Fetch<any, any> => {
  if (ISMOCK) {
    return MOCKFUNC(url);
  }
  return (data: any) =>
    axios.post(
      "",
      {
        ...data
      },
      {
        params: { yc: url }
      }
    );
};

// 单纯处理的get请求 的封装函数
export const createGetFetch = (url: string) =>
  (params?: any) => axios.get(url, { params: params ? params : {} });

