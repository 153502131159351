/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import React, { useCallback, useState } from "react";
import style from "./index.module.scss";
import Statistics from "./components/Statistics/index";
import NewBlocks from "./components/NewBlocks/index";
import Chart from "./components/Chart/index";
import Deals from "./components/Deals/index";
import Contract from "./components/Contract/index";
import { Search } from "@src/utils/apis";
import { useNavigate, useParams } from "react-router-dom";
import { notification } from "tea-component";
import Map from "@src/routes/main/Home/components/Map";

export default function Home({ isShowSearch }: { isShowSearch: boolean }) {
  const { chainId } = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const onSearch = useCallback(() => {
    Search({
      Id: value,
      ChainId: chainId
    }).then((res) => {
      if (res.Data?.Type !== undefined) {
        switch (res.Data.Type) {
          case 0:
            navigate(`/view/${res.Data.ChainId}/block/${res.Data.Data}`);
            break;
          case 1:
            navigate(`/view/${res.Data.ChainId}/transaction/${res.Data.Data}`);
            break;
          case 2:
            navigate(`/view/${res.Data.ChainId}/contract/${res.Data.Data}`);
            break;
          // case 3:
          //   navigate(`/${res.Data.ChainId}/contract/${res.Data.Data}`);
          //   break;
          default:
            notification.error({
              title: `没有搜索到任何结果`,
              description: `搜索： ${value}没有任何结果`,
              unique: true,
              duration: 3000
            });
            break;
        }
      }
    });
  }, [value, chainId]);
  const onKeyPress = useCallback(
    (e) => {
      if (e.which === 13) {
        onSearch();
        e.stopPropagation();
        e.preventDefault();
        return;
      }
    },
    [onSearch]
  );
  return (
    <>
      <div className={isShowSearch ? style.fade_up : style.fade_down}>
        <div className={style.search_info}>
          <div className={style.top_info_search_input}>
            <input
              value={value}
              onChange={(input) => {
                setValue(input.target.value);
              }}
              onKeyPress={onKeyPress}
              placeholder="请输入区块哈希  / 区块高度 / 交易id / 合约名称搜索"
            />
          </div>
          <div onClick={onSearch} className={style.top_info_search_bt}>
            搜索
          </div>
        </div>
      </div>
      <div className={style.new_block}>
        <NewBlocks />
      </div>
      <div className={style.top}>
        <div className={style.statistics}>
          <Statistics />
          <div className={style.chart}>
            <Chart />
            <Map />
          </div>
        </div>
      </div>
      <div className={style.news}>
        <Deals />
        <Contract />
      </div>
    </>
  );
}
