/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */
import React, { useCallback, useState } from "react";
import Home from "./Home";
import Chain from "./Chain";
import Block from "./Block";
import BlockDetail from "./Block/Detail";
import Transaction from "./Transaction";
import TransactionDetail from "./Transaction/Detail";
import Contract from "./Contract";
import ContractDetail from "./Contract/Detail";
import Node from "./Node";
import Origin from "./Origin";
import User from "./User";
import style from "./index.module.scss";
import { Routes, Route, Navigate, useNavigate, useParams } from "react-router-dom";
import ChainSelect from "./components/ChainSelect";
import { Dropdown, List } from "tea-component";
// import LogoImg from "@imgs/logo.png";
import ImgFlower from "@imgs/menu.png";
import TWEEN from "@tweenjs/tween.js";
import Operation from "@src/routes/main/Operation";

function animate(time: any) {
  requestAnimationFrame(animate);
  TWEEN.update(time);
}

requestAnimationFrame(animate);
export default function Main() {
  const menu = close => (
    <List type="option">
      <List.Item onClick={close}><a className={style.ecosystem} rel={"noreferrer"} href="http://management.ynuchain.cn">云大链管理平台</a></List.Item>
      { /*  <List.Item onClick={close}><a className={style.ecosystem} rel={"noreferrer"} href="http://trace.ynuchain.cn">机场溯源</a></List.Item>*/ }
      { /*  <List.Item onClick={close}><a className={style.ecosystem} rel={"noreferrer"} href="http://device.ynuchain.cn">装备全生命周期管理</a></List.Item>*/ }
      <List.Item onClick={close}><a className={style.ecosystem} rel={"noreferrer"} href="http://contractverify.ynuchain.cn/">合约验证</a></List.Item>
      <List.Item onClick={close}><a className={style.ecosystem} rel={"noreferrer"} href="http://certificate.ynuchain.cn">确权系统</a></List.Item>
    </List>
  );
  const { chainId } = useParams();
  const navigate = useNavigate();
  const updateChainId = useCallback((value) => {
    window.open(`/view/${value}/home`);
  }, []);
  const onInit = useCallback((value) => {
    setTimeout(() => {
      navigate(`/view/${value}/home`, { replace: true });
    }, 0);
  }, []);
  const [showSearch, setShowSearch] = useState(false);
  const handleSearchClick = useCallback(() => {
    setShowSearch(!showSearch);
  }, [showSearch]);
  const [showOperation, setShowOperation] = useState(false);
  const handleOperationClick = useCallback(() => {
    setShowOperation(!showOperation);
  }, [showOperation]);
  return (
    <>
      <div className={style.header}>
        <div className={style.header_c}>
          <div>
            {/* <img onClick={() => navigate(`/${chainId}/home`)} src={LogoImg} className={style.header_logo} />*/}
            <span>云大链浏览器</span>
          </div>
          <div className={style.header_setting}>
            <ChainSelect value={chainId} onChange={updateChainId} onInit={onInit} />
            <img onClick={() => navigate(`/view/${chainId}/chain`)} src={ImgFlower}
                 className={style.header_seeting_icon} />
            <div className={style.header_setting_right}>
              <span onClick={handleSearchClick}>搜索</span>
              <span onClick={handleOperationClick}>跨链</span>
              <Dropdown
                trigger="hover"
                clickClose={false}
                appearance={"link"}
                style={{ marginLeft: 5 }}
                button="生态"
              >
                {menu}
              </Dropdown>
            </div>
          </div>
        </div>
      </div>

      <Operation show={showOperation} close={handleOperationClick} />

      {chainId && chainId !== "v" && (
        <Routes>
          <Route path="home" element={<Home isShowSearch={showSearch} />} />
          <Route path="node" element={<Node />} />
          <Route path="chain" element={<Chain />} />
          <Route path="block" element={<Block />} />
          <Route path="block/:blockHeight" element={<BlockDetail />} />
          <Route path="transaction" element={<Transaction />} />
          <Route path="transaction/:txId" element={<TransactionDetail />} />
          <Route path="contract" element={<Contract />} />
          <Route path="contract/:contractName" element={<ContractDetail />} />
          <Route path="origin" element={<Origin />} />
          <Route path="user" element={<User />} />
          <Route path="*" element={<Navigate to="home" />} />
        </Routes>
      )}
    </>
  );
}
