/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'tea-component/dist/tea.css';
import App from './App';
import store from './store';
import { Provider } from 'react-redux';
import './utils/bgcanvas.js';

ReactDOM.render(
  // <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  // </React.StrictMode>
  ,document.getElementById('root'),
);

