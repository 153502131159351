/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

/* eslint-disable */
!(function () {
  function t() {
    return { l: 1, z: -1, o: 0.5, c: '255,255,255', n: 120 };
  }
  function o() {
    a = m.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    c = m.height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  }
  function i() {
    r.clearRect(0, 0, a, c);
    r.fillStyle = 'rgb(255,255,255)';
    let n;
    let e;
    let t;
    let o;
    let m;
    let l;
    s.forEach((i, x) => {
      for (
        i.x += i.xa,
          i.y += i.ya,
          i.xa *= i.x > a || i.x < 0 ? -1 : 1,
          i.ya *= i.y > c || i.y < 0 ? -1 : 1,
          r.fillRect(i.x - 1.5, i.y - 1.5, 3, 3),
          e = x + 1;
        e < u.length;
        e++
      )
        (n = u[e]),
          null !== n.x &&
            null !== n.y &&
            ((o = i.x - n.x),
            (m = i.y - n.y),
            (l = o * o + m * m),
            l < n.max &&
              (n === y && l >= n.max / 2 && ((i.x -= 0.03 * o), (i.y -= 0.03 * m)),
              (t = (n.max - l) / n.max),
              r.beginPath(),
              (r.lineWidth = t / 2),
              (r.strokeStyle = `rgba(${d.c},${t + 0.2})`),
              r.moveTo(i.x, i.y),
              r.lineTo(n.x, n.y),
              r.stroke()));
    });
	x(i);
  }
  let a;
  let c;
  let u;
  const m = document.createElement('canvas');
  const d = t();
  const l = `c_n${d.l}`;
  const r = m.getContext('2d');
  const x =
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.requestAnimationFrame ||
    window.requestAnimationFrame ||
    function (n) {
      window.setTimeout(n, 1e3 / 45);
    };
  const w = Math.random;
  const y = { x: null, y: null, max: 2e4 };
  (m.id = l),
    (m.style.cssText = `position:fixed;top:0;left:0;z-index:${d.z};opacity:${d.o};pointer-events:none;`),
    document.body.appendChild(m),
    o(),
    (window.onresize = o),
    (window.onmousemove = function (n) {
      (n = n || window.event), (y.x = n.clientX), (y.y = n.clientY);
    }),
    (window.onmouseout = function () {
      (y.x = null), (y.y = null);
    });
  const s = [];
  for (let f = 0; d.n > f; f++) {
    const h = w() * a;
    const g = w() * c;
    const v = 2 * w() - 1;
    const p = 2 * w() - 1;
    s.push({ x: h, y: g, xa: v, ya: p, max: 6e3 });
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  u = s.concat([y]);
  setTimeout(() => {
    i();
  }, 100);
})();
