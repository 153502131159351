import React, { useCallback, useEffect, useState } from "react";
import { Button, Cascader, Form, Input, InputAdornment, notification, StatusTip } from "tea-component";
import { CrossChainReq, GetSupportedChain } from "@src/utils/apis";
import formStyle from "../form.module.scss";
import indexStyle from "./index.module.scss";

export default function CrossChain() {
  // 用于显示支持跨链的菜单
  const [supporetdChains, setSupporetdChains] = useState([]);
  // 后端原始数据，用于显示BDS
  const [_supporetdChains, _setSupporetdChains] = useState([]);
  // 用户已经选择的链 [[ChainType,ChainId],...]
  const [selectedChains, setSelectedChains] = useState([]);
  // 用于BDS的链[ChainId, ChainId,...]
  const [_selectedChains, _setSelectedChains] = useState([]);
  const [key, setKey] = useState("");
  const [str, setStr] = useState("");


  const [res, setRes] = useState("");
  const [canSubmit, setCanSubmit] = useState(true);
  const handleSubmit = useCallback(async () => {
    if (selectedChains.length < 2 || key === "" || str === "") {
      console.log(selectedChains, key, str);
      notification.error({ description: "请填写内容" });
      return;
    }
    setCanSubmit(false);
    const p = [];
    for (const selectedChain of selectedChains) {
      p.push(selectedChain[selectedChain.length - 1]);
    }
    const data = {
      Participants: p,
      Key: key,
      Msg: str
    };
    try {
      const res = await CrossChainReq(data);
      try {
        setRes(res.Data);
      } catch (e) {
        console.log(e);
        notification.error({ description: "跨链失败" });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setCanSubmit(true);
    }
  }, [selectedChains, key, str]);

  useEffect(() => {
    (async () => {
      try {
        let res = await GetSupportedChain({});
        res = JSON.parse(res.Data).Participants;
        console.log(res);
        const tempParticipants = [];
        for (const participant of res) {
          const temp: { label: string, value: string, children: {}[] } = {
            label: "",
            value: "",
            children: []
          };
          temp.label = participant.ChainType;
          temp.value = (participant as any).ChainType;
          temp.children = [];
          for (const chainId of participant.ChainIds) {
            const tempChild = {
              label: "",
              value: ""
            };
            tempChild.label = (chainId as any).ChainId;
            tempChild.value = (chainId as any).ChainId;
            temp.children.push(tempChild);
          }
          tempParticipants.push(temp);
        }
        // @ts-ignore
        setSupporetdChains(tempParticipants);
        _setSupporetdChains(res);
      } catch (e) {
        console.log(e);
        notification.error({ description: `拉取支持的跨链参与方失败:${e}` });
      }
    })();
  }, []);

  return (
    <>
      <Form className={formStyle.form_}>
        <Form.Item label="跨链参与方">
          <Cascader
            multiple
            clearable
            type="menu"
            data={supporetdChains}
            onChange={(value) => {
              setSelectedChains(value);
              const p = [];
              for (const selectedChain of value) {
                p.push(selectedChain[selectedChain.length - 1]);
              }
              _setSelectedChains(p);
              console.log(p);
            }
            }
          />
        </Form.Item>
        <Form.Item label="数据Key">
          <InputAdornment before="String">
            <Input value={key} onChange={value => setKey(value)} />
          </InputAdornment>
        </Form.Item>
        <Form.Item label="数据value">
          <InputAdornment before="String">
            <Input value={str} onChange={value => setStr(value)} />
          </InputAdornment>
        </Form.Item>

        <Form.Action>
          <Button type="primary" disabled={!canSubmit} onClick={handleSubmit}>提交</Button>
          {!canSubmit && <StatusTip.LoadingTip />}
        </Form.Action>
      </Form>

      {
        _selectedChains.length > 0 && <>
          <p className={indexStyle.head}>区块链信息：</p>
          {
            _selectedChains.map((participant) => {
              const temp = {
                ChainType: String,
                ChainId: String,
                ListenerAdd: String,
                Consensus: String,
                NodeAddr: String,
                NodeCount: String
              };
              for (const p of _supporetdChains) {
                let canBreak = false;
                for (const chain of p.ChainIds) {
                  if (chain.ChainId === participant) {
                    temp.ChainType = p.ChainType;
                    temp.ChainId = chain.ChainId;
                    temp.ListenerAdd = chain.ListenerAdd;
                    temp.Consensus = chain.Consensus;
                    temp.NodeAddr = chain.NodeAddr;
                    temp.NodeCount = chain.NodeCount;
                    canBreak = true;
                    break;
                  }
                }
                if (canBreak) {
                  break;
                }
              }
              console.log(temp);
              return <>
                <div className={indexStyle.chain}>
                  <div className={indexStyle.lable}>
                    <p>链类型：</p>
                    <p>链Id：</p>
                    <p>共识算法：</p>
                    <p>节点地址：</p>
                    <p>节点数量：</p>
                    <p>代理地址：</p>
                  </div>
                  <div className={indexStyle.value}>
                    <p>{temp.ChainType}</p>
                    <p>{temp.ChainId}</p>
                    <p>{temp.Consensus}</p>
                    <p>{temp.NodeAddr}</p>
                    <p>{temp.NodeCount}</p>
                    <p>{temp.ListenerAdd}</p>
                  </div>
                </div>
                <hr />
              </>;
            })
          }
        </>
      }


      {res.length > 0 && <>
        <p className={indexStyle.resTitle}>跨链操作结果：</p>
        {
          <>
            <div className={indexStyle.res}>
              <p className={"info"}>{res}</p>
              <hr />
            </div>
          </>
        }
      </>
      }
    </>
  );
}
